import { Auth } from 'aws-amplify';
import { GraphQLClient } from 'graphql-request';

export const authGraphqlClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_BACKEND_URL}/v1/graphql`,
  {
    requestMiddleware: async (options) => {
      const idToken = await Auth.currentAuthenticatedUser().then((user) =>
        user.getSignInUserSession().getIdToken().getJwtToken()
      );
      // eslint-disable-next-line no-param-reassign
      options.headers = {
        ...options.headers,
        authorization: `Bearer ${idToken}`,
      };
      return options;
    },
  }
);
export const adminGraphqlClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_BACKEND_URL}/v1/graphql`,
  {
    headers: {
      'x-hasura-admin-secret': process.env.HASURA_ADMIN_SECRET ?? '',
    },
  }
);
export const graphqlClient = new GraphQLClient(
  `${process.env.NEXT_PUBLIC_BACKEND_URL}/v1/graphql`,
  {}
);
export function fetchByPublic<TData, TVariables extends { [key: string]: any }>(
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    graphqlClient.request({
      document: query,
      variables,
      requestHeaders,
    });
}

export function fetchByAdmin<TData, TVariables extends { [key: string]: any }>(
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    adminGraphqlClient.request({
      document: query,
      variables,
      requestHeaders,
    });
}

export function fetchByAuth<TData, TVariables extends { [key: string]: any }>(
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit['headers']
) {
  return async (): Promise<TData> =>
    authGraphqlClient.request({
      document: query,
      variables,
      requestHeaders,
    });
}
