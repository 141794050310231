import type { ReactNode } from 'react';

import type {
  GetCampSiteByIdQuery,
  ReviewByPkQuery,
  UserByWhereQuery,
} from '@/__generated__/public-graphql';
import type { GetLoginUserQuery } from '@/__generated__/user-graphql';

export type IconType =
  | 'favorite'
  | 'not-favorite'
  | 'favorite-base'
  | 'favorite-flag'
  | 'favorite-star'
  | 'search'
  | 'search-round'
  | 'setting'
  | 'mail'
  | 'menu'
  | 'humberger'
  | 'minus'
  | 'radio-on'
  | 'radio-off'
  | 'round-minus'
  | 'round-plus'
  | 'round-plus-outline'
  | 'mypage-account'
  | 'mypage-favorite'
  | 'mypage-review'
  | 'mypage-visit'
  | 'notification'
  | 'circle'
  | 'tent'
  | 'trash'
  | 'key'
  | 'switch'
  | 'pencil'
  | 'person'
  | 'arrow-top'
  | 'arrow-bottom'
  | 'arrow-right'
  | 'arrow-right-primary'
  | 'arrow-left'
  | 'arrow-left-primary'
  | 'arrow-top-double'
  | 'arrow-bottom-double'
  | 'like'
  | 'link'
  | 'dot'
  | 'dots'
  | 'dots-lg'
  | 'image'
  | 'upload'
  | 'edit-notentered'
  | 'edit-entered'
  | 'edit-alert'
  | 'edit-circle'
  | 'edit-pencil'
  | 'person'
  | 'add'
  | 'file'
  | 'filter'
  | 'close-wh'
  | 'close-whg'
  | 'close-gr'
  | 'close-red'
  | 'checkbox-on'
  | 'checkbox-off'
  | 'checkbox-round-on'
  | 'checkbox-round-off'
  | 'checkbox-it-on'
  | 'checkbox-it-off'
  | 'sns-facebook'
  | 'sns-amazon'
  | 'sns-line'
  | 'sns-line-friend'
  | 'sns-google';

export type CardSize = 'sm' | 'lg' | 'vt' | 'hr' | 'wide';

type TermItem = { name: string; caption?: string; children: string[] };

export type SearchTerms = {
  area: TermItem[];
  equipment: TermItem[];
  location: TermItem[];
  carStock: TermItem[];
  bath: TermItem[];
  businessPeriod: TermItem[];
  checkIn: TermItem[];
  details: TermItem[];
};

export type SlideItem = {
  id: number;
  imageUrl: string;
  title: string;
  userId: string;
};

export type DialogType = {
  open: boolean;
  widthFull?: boolean;
  type:
    | ''
    | 'CAMP_IMAGE_SLIDE'
    | 'FAVORITE'
    | 'SEARCH'
    | 'NOTIFY'
    | 'COMMON'
    | 'COMMON_SUB'
    | 'COMMON_THIRD'
    | 'GLOBAL_NAVI';
  props: Record<any, any>;
  children?: ReactNode;
};

export enum Page {
  LOGIN,
  TOP,
  ABOUT,
  THEME,
  SEARCH,
  CAMPSITE,
  CAMPER_ACCOUNT,
  CAMPER_FAVORITE,
  CAMPER_REVIEW,
  CAMPER_VISITED,
  MYPAGE_ACCOUNT,
  MYPAGE_FAVORITE,
  MYPAGE_REVIEW,
  MYPAGE_VISITED,
  MYPAGE_HISTORY,
  FAQ,
  CONTACT,
  MAGAZINE,
  NEWS,
  TERMS,
  TOKUSHOHO,
  PRIVACY,
  GUIDELINE,
  EDIT,
}

export enum InputPattern {
  EMAIL = '[\\w\\-._+]+@[\\w\\-._]+\\.[A-Za-z]+',
  POSTCODE = '^[0-9]{3}-?[0-9]{4}$',
  PHONE_NUMBER = '^0[789]0[0-9]{4}[0-9]{4}$',
  PASSWORD = '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9]{8,50}$',
  ALPHANUMERICA = '^[a-zA-Z0-9!-/:-@¥[-`{-~]*$',
  NUMBER = '^[0-9]+$',
}

export type SelectPeriod = {
  startHour: string;
  startMinute: string;
  endHour: string;
  endMinute: string;
};

export type UploadFiles = {
  campsite: [];
  equipment: [];
  landscape: [];
  other: [];
};

export type CampDetail = {
  phoneNumber: string[];
  siteUrl: string;
  imageUrl: string;
  title: string;
  description: string;
};

export type CampInformation = {
  equipment: string[];
  location: string[];
  carStock: string[];
  businessPeriod: string[];
  holiday: string[];
  checkIn: SelectPeriod;
  checkOut: SelectPeriod;
  bath: string[];
  details: string[];
};

export type FavoriteItem = {
  id: string;
  imageUrl: string;
  area: string;
  title: string;
};

export type CampsiteAttr = Omit<Campsite, 'campsite_likes_aggregate'>;

export type Campsite = NonNullable<GetCampSiteByIdQuery['campsite_by_pk']> & {
  __typename?: string;
};

export type Review = NonNullable<ReviewByPkQuery['review_by_pk']> & {
  __typename?: string | undefined;
};
export enum CampSiteLikeListType {
  ALL = 'ALL',
  SPECIAL = 'SPECIAL',
  I_WANT_TO_GO = 'I_WANT_TO_GO',
}

export type PublicUser = NonNullable<UserByWhereQuery['user_public'][0]>;
export type User = NonNullable<GetLoginUserQuery['user'][0]>;

export type UploadImageInput = {
  file: File;
  preview: string;
  title: string;
  alt: string;
};
export type UploadedImageInput = {
  id: string;
  title: string;
  alt: string;
  file_name: string;
  user_id: string;
};
