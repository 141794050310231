import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    white: {
      main: string;
    };
    green: {
      main: string;
    };
    red: {
      main: string;
    };
    line: {
      main: string;
    };
    amazon: {
      main: string;
    };
    facebook: {
      main: string;
    };
    transparent: {
      main: string;
    };
  }
  interface PaletteOptions {
    white?: {
      main?: string;
    };
    green?: {
      main: string;
    };
    red?: {
      main: string;
    };
    line?: {
      main: string;
    };
    amazon?: {
      main: string;
    };
    facebook?: {
      main: string;
    };
    transparent?: {
      main: string;
    };
  }
}

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#2A607E',
    },
    secondary: {
      main: '#E08F55',
    },
    error: {
      main: '#9B4450',
    },
    white: { main: '#ffffff' },
    grey: { 50: '#E3E9E6', 100: '#818982' },
    green: { main: '#0A6058' },
    red: { main: '#9B4450' },
    line: { main: '#1FC755' },
    amazon: { main: '#F09000' },
    facebook: { main: '#325DAA' },
    transparent: {
      main: 'transparent',
    },
  },
  typography: {
    fontFamily: ['"Noto Sans JP"', 'Roboto', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html{
          height: 100vh;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        body {
          max-height: 100vh;
          background: #f4f8f6;
        }
        p, h2, h3, h4 {
          margin: unset;
          color: #333333;
          letter-spacing: 0.05em;
        }
        a {
          text-decoration: none;
        }
        ul, ol {
          margin: unset;
          padding: unset;
        }
        li {
          list-style: none;
        }
        @keyframes arrowmove{
          0%{bottom:1%;}
          50%{bottom:3%;}
         100%{bottom:1%;}
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: '0.05em',
          lineHeight: 1.7,
        },
        body1: {
          color: '#333333',
        },
        body2: {
          color: '#333333',
          fontWeight: 500,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            color: '#ffffff',
            backgroundColor: 'rgb(153 165 159 / 40%)',
          },
        },
        startIcon: {
          marginLeft: 'unset',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .Mui-checked + span': {
            backgroundColor: '#ffffff  !important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 'unset',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 'unset',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          margin: 'auto',
          '@media screen and (min-width: 900px)': {
            marginTop: '48px',
            maxWidth: '506px',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          margin: 'auto',
          '@media screen and (min-width: 900px)': {
            marginTop: '48px',
            maxWidth: '506px',
            borderRadius: '40px 40px 0 0',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          background: '#ffffff',
          padding: '14px 32px 14px 14px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          margin: 'unset !important',
          maxWidth: 'unset !important',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          margin: 'unset !important',
          maxWidth: 'unset !important',
        },
        paper: {
          width: '180px',
          borderRadius: '8px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: '68px',
          fontSize: '16px',
          borderRadius: '8px !important',
          background: '#ffffff',
          '& fieldset': {
            border: 'solid 1px #e3e9e6',
            borderRadius: '8px',
          },
          '&.Mui-error fieldset': {
            borderWidth: '2px',
          },
        },
        input: {
          letterSpacing: '0.050em',
          '&::placeholder': {
            color: '#818982',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: { textDecoration: 'none' },
      },
    },
  },
});

export default theme;
