import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { Box } from '@mui/material';
import { PrismicProvider } from '@prismicio/react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Amplify } from 'aws-amplify';
import type { AppProps } from 'next/app';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type ReactElement, useEffect, useState } from 'react';

import { useGetMyPointNotificationQuery } from '@/__generated__/user-graphql';
import NotificationArea from '@/contents/General/NotificationArea';
import { GlobalContextProvider } from '@/contents/hooks/state';
import { LoadingPanel } from '@/layouts/style';
import { GTMTag, useGTM } from '@/lib/gtm';
import { useLogRocket } from '@/modules/Surveillance/useLogRocket';
import useLoginController from '@/modules/User/useLoginController';
import useGetLoginUser from '@/modules/User/user/useGetLoginUser';
import { linkResolver } from '@/prismicio';
import createEmotionCache from '@/styles/createEmotionCache';
import StyleProvider from '@/styles/StyleProvider';
import type { AppPropsWithLayout } from '@/types';

const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppPropsWithLayout {
  emotionCache?: EmotionCache;
}

Amplify.configure({
  Auth: {
    // mandatorySignIn: false,
    identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
    region: 'ap-northeast-1',
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'phone',
        'profile',
      ],
      redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_URL,
      redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_URL,
      responseType: 'code',
    },
  },
});

const queryClient = new QueryClient();
const Content = ({
  children,
  userAgent,
}: {
  children: React.ReactNode;
  userAgent: string;
}) => {
  // 新規会員登録後、signupページに遷移する
  const router = useRouter();
  const {
    data,
    refetch,
    isFetched: isMyPointFetched,
  } = useGetMyPointNotificationQuery();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { isLogin, isFetched } = useGetLoginUser();
  const isFacebookBot = userAgent?.match(
    /^facebookexternalhit.*$|^facebookcatalog.*$/
  );

  useEffect(() => {
    if (!data?.point || data.point.length < 0) {
      setIsPageLoading(false);
      return;
    }
    if (data.point.length > 0) {
      if (
        data.point.find((item) => {
          return item.earned_type === 'user';
        }) &&
        router.pathname !== '/signup'
      ) {
        setIsPageLoading(true);

        router.push('/signup').then(() => {
          setIsPageLoading(false);
        });
      } else {
        setIsPageLoading(false);
      }
    } else {
      setIsPageLoading(false);
    }
  }, [data]);
  useEffect(() => {
    if (!isLogin) return;
    refetch();
  }, [isLogin]);

  useLoginController();
  // ログイン状態が確認できるまでローディングを表示
  if (!isFetched || (!isMyPointFetched && isLogin) || isPageLoading) {
    return (
      <LoadingPanel>
        <Box width={133} height={77} />
        <div style={{ display: 'none' }}>{children}</div>
      </LoadingPanel>
    );
  }
  return (
    <>
      <NotificationArea />
      {children}
    </>
  );
};
const MyApp = ({
  Component,
  // @ts-ignore
  pageProps: { dehydratedState, ...pageProps },
  emotionCache = clientSideEmotionCache,
  userAgent,
}: MyAppProps &
  AppProps & {
    userAgent: string;
  }) => {
  useGTM();

  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);
  useLogRocket();

  return (
    <>
      <GTMTag />

      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <PrismicProvider
            linkResolver={linkResolver}
            internalLinkComponent={({ href, children, ...props }) => (
              <Link href={href}>
                <a {...props}>{children}</a>
              </Link>
            )}
          >
            <GlobalContextProvider>
              <CacheProvider value={emotionCache}>
                <StyleProvider>
                  <Content userAgent={userAgent}>
                    {getLayout(<Component {...pageProps} />)}
                  </Content>
                </StyleProvider>
              </CacheProvider>
            </GlobalContextProvider>
          </PrismicProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
