import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useEffect } from 'react';

export const useLogRocket = () => {
  useEffect(() => {
    if (!window || !window.document) return;
    if ((process.env.NEXT_PUBLIC_LOG_ROCKET_ID ?? '') !== '') {
      LogRocket.init(process.env.NEXT_PUBLIC_LOG_ROCKET_ID as string);
      setupLogRocketReact(LogRocket);
    }
  }, []);
};
