import { Stack as MuiStack } from '@mui/material';
import type { StackProps } from '@mui/material/Stack';
import type { ElementType } from 'react';
import React from 'react';

type Props = StackProps & {
  column?: boolean;
  center?: boolean;
  alignCenter?: boolean;
  alignEnd?: boolean;
  justifyCenter?: boolean;
  between?: boolean;
  around?: boolean;
  space?: number;
  sx?: Record<string, unknown>;
  component?: ElementType<any>;
};

const Stack = ({
  column,
  flexWrap,
  center,
  alignCenter,
  alignEnd,
  justifyCenter,
  between,
  around,
  space,
  children,
  onClick,
  padding,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  width,
  height,
  sx,
  component,
}: Props) => {
  const getjustify = () => {
    if (center || justifyCenter) {
      return 'center';
    }
    if (between) {
      return 'space-between';
    }
    if (around) {
      return 'space-around';
    }
    return '';
  };

  const getAlignItems = () => {
    if (center || alignCenter) {
      return 'center';
    }
    if (alignEnd) {
      return 'flex-end';
    }
    return '';
  };

  return (
    <MuiStack
      direction={column ? 'column' : 'row'}
      justifyContent={getjustify()}
      alignItems={getAlignItems()}
      spacing={space}
      onClick={onClick}
      flexWrap={flexWrap}
      sx={sx}
      padding={padding}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      width={width}
      height={height}
      component={component || 'div'}
    >
      {children}
    </MuiStack>
  );
};

export default Stack;
