import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import type {
  TypographyProps,
  TypographyTypeMap,
} from '@mui/material/Typography';
import * as MuiTypography from '@mui/material/Typography';
import React from 'react';

import theme from '@/styles/theme';

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    white: true;
    green: true;
    red: true;
  }
}

type Props = TypographyProps & {
  ellipsis?: number;
  wrap?: boolean;
};

const getColor = (svgColor: string) => {
  switch (svgColor) {
    case 'white':
      return theme.palette.white.main;
    case 'grey':
      return theme.palette.grey[100];
    case 'red':
      return theme.palette.red.main;
    case 'green':
      return theme.palette.green.main;
    default:
      return '';
  }
};

const StyledTypography = styled(MuiTypography.default)<{
  clamp: number;
  wrap: 'true' | 'false';
}>`
  ${(props) => props.wrap === 'true' && 'white-space: pre-wrap;'}
  &.ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => props.clamp && `${props.clamp}`};
    overflow: hidden;
  }
` as OverridableComponent<
  TypographyTypeMap<{
    clamp?: number;
    wrap?: 'true' | 'false';
  }>
>;

const Typography = ({
  children,
  variant,
  color,
  fontSize,
  fontWeight,
  ellipsis,
  sx,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  lineHeight,
  letterSpacing,
  textAlign,
  onClick,
  noWrap,
  whiteSpace,
  wrap,
}: Props) => {
  return (
    <StyledTypography
      variant={variant}
      color={
        color === 'white' ||
        color === 'grey' ||
        color === 'red' ||
        color === 'green'
          ? getColor(color)
          : color
      }
      fontSize={fontSize}
      fontWeight={fontWeight}
      className={ellipsis ? 'ellipsis' : ''}
      clamp={ellipsis || 0}
      sx={sx}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      textAlign={textAlign}
      noWrap={noWrap}
      whiteSpace={whiteSpace}
      onClick={onClick}
      wrap={wrap ? 'true' : 'false'}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
