import styled from '@emotion/styled';
import { Box } from '@mui/material';

import theme from '@/styles/theme';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100vw;
  ${theme.breakpoints.up('md')} {
    left: 0;
    right: 0;
    padding-top: 48px;
    min-width: 506px;
    max-width: 506px;
    min-height: calc(100vh - 48px);
  }
  main {
    display: flex;
    flex-direction: column;
    max-width: inherit;
    min-height: calc(100vh - 48px);
    background: #f4f8f6;
    z-index: 1;
    -ms-overflow-style: none;
    margin-top: 64px;
    min-height: 100vh;
    ${theme.breakpoints.up('md')} {
      margin-top: unset;
      border-radius: 40px 40px 0 0;
      overflow: hidden;
    }
  }
`;

export const PCArea = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing(6)} ${theme.spacing(7)};
  width: 100%;
  background: url(/assets/images/common/bg_pc.jpg) no-repeat;
  background-size: cover;
  > div {
    width: 100%;
    max-width: 1400px;
  }
`;

export const LoadingPanel = styled(Box)`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url(/assets/images/common/bg_pc.jpg) no-repeat;
  background-size: cover;
  z-index: 100;
  > div {
    position: absolute;
    bottom: 47%;
    border-radius: 10px 10px 0 0;
    background: #0a6058;
    mask-image: url('/assets/images/common/loading_logo.svg');
    mask-repeat: no-repeat;
    mask-position: bottom;
    will-change: animation;
    animation: loading 2.5s ease-in-out infinite;
  }
  @keyframes loading {
    0% {
      height: 20px;
    }
    50% {
      height: 77px;
    }
    100% {
      height: 20px;
    }
  }
`;
