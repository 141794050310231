import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useContext, useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import { useScrollRestore } from '@/contents/hooks/ScrollRestore';
import { GlobalContext } from '@/contents/hooks/state';
import { LoadingPanel } from '@/layouts/style';
import { Page } from '@/types/domain';

import theme from './theme';

type Props = {
  children?: ReactNode;
};

function StyleProvider({ children }: Props) {
  const router = useRouter();
  const saveScroll = useScrollRestore();
  const {
    isLoading,
    setIsLoading,
    dialogStatus,
    setDialogStatus,
    page,
    isEditLoading,
    historyScrollValue,
    setHistoryScrollValue,
    isBrowserBack,
    setIsBrowserBack,
  } = useContext(GlobalContext);

  if (process.browser) {
    smoothscroll.polyfill();
  }

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsLoading(true));
    router.events.on('routeChangeComplete', () => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (page !== Page.SEARCH) {
      setDialogStatus({ ...dialogStatus, open: false });
    }
  }, [page]);

  useEffect(() => {
    if (!isBrowserBack) {
      window?.scrollTo(0, 0);
      setHistoryScrollValue(null);
    } else {
      setIsLoading(false);
      setIsBrowserBack(false);
    }
  }, [router.beforePopState]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {(isEditLoading ||
        (historyScrollValue && historyScrollValue[0].url.match('search'))) && (
        <LoadingPanel>
          <Box width={133} height={77} />
        </LoadingPanel>
      )}
      <Box sx={{ opacity: isLoading ? 0 : 1, transition: 'opacity ease 0.2s' }}>
        {children}
      </Box>
    </ThemeProvider>
  );
}

export default StyleProvider;
