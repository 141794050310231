import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import { gql } from 'graphql-request';
import { useEffect, useState } from 'react';

import {
  useAfterLoginMutation,
  useGetLoginUserQuery,
  useWithdrawMutation,
} from '@/__generated__/user-graphql';

export default () => {
  const [isFetched, setIsFetched] = useState(false);
  const { mutateAsync: withdraw } = useWithdrawMutation({});
  const { data, refetch, ...args } = useGetLoginUserQuery(undefined, {
    cacheTime: 60 * 10,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync } = useAfterLoginMutation();
  const { data: cognitoData, refetch: refetchCognito } = useQuery(
    ['cognitoId'],
    async () => {
      try {
        const cognitoUserName =
          ((await Auth.currentSession()).getIdToken().payload[
            'cognito:username'
          ] as string) || null;
        return cognitoUserName;
      } catch (e) {
        console.log(e);
        setIsFetched(true);
      }

      return null;
    },
    {
      onError: (e) => {
        setIsFetched(true);
      },
    }
  );
  // isFetchedが30秒たってもfalseなら強制的にtrueにする
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFetched(true);
    }, 30000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // safariのbfcache対策
    window.addEventListener('pageshow', function (event) {
      if (event.persisted) {
        console.log('bfcache');
        refetchCognito();
      }
    });
  }, []);

  useEffect(() => {
    if (cognitoData === null) {
      setIsFetched(true);
    }
  }, [cognitoData]);
  useEffect(() => {
    if (data?.user[0]?.withdrawal_date) {
      Auth.signOut({ global: true });
    }
  }, [data]);
  const { data: query } = useQuery(
    ['userFetch'],
    async () => {
      const cognitoUserName = cognitoData || null;
      // sessionがnullなら何もしない
      if (!cognitoUserName) return null;
      const r = await refetch();
      const user = r.data?.user?.[0];
      if (user) {
        setIsFetched(true);
        return null;
      }
      await mutateAsync({
        cognitoUserId: cognitoUserName,
      });
      const r2 = await refetch();
      if (r2.data?.user?.[0]) {
        console.log('login success');
        setIsFetched(true);
        return r2.data?.user?.[0];
      }
      console.log('fail');
      // Auth.signOut();
      setIsFetched(true);
      return null;
    },
    {
      enabled: !!cognitoData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const resign = async () => {
    await withdraw({});
  };
  const logout = async () => {
    Auth.signOut({ global: true });
  };

  const user = data?.user?.[0];
  if (user) {
    return {
      refetch,
      isLogin: true,
      user,
      isLoaded: args.isFetched,
      isFetched: true,
      resign,
      logout,
    };
  }

  return {
    refetch,
    isLogin: false,
    user: null,
    isLoaded: args.isFetched,
    isFetched,
    resign,
    logout,
  };
};
const query = gql`
  query GetLoginUser {
    user {
      display_name
      email
      birthday
      background_image_id
      camping_days
      created_at
      icon_image_id
      id
      liked_gear
      self_introduction
      sex
      start_history
      total_like_num
      total_point
      total_review_num
      total_visited_num
      updated_at
      url
      url_title
      user_email
      withdrawal_date
      campsite_likes_aggregate {
        aggregate {
          count
        }
      }
      visiteds_aggregate {
        aggregate {
          count
        }
      }
      user_notifications_aggregate {
        aggregate {
          count
        }
      }
      background_icon {
        user_id
        title
        id
        file_name
        created_at
        alt
      }
      icon {
        user_id
        title
        id
        file_name
        created_at
        alt
      }
    }
  }
`;

const query2 = gql`
  mutation AfterLogin($cognitoUserId: String!) {
    afterLogin(cognitoUserId: $cognitoUserId)
  }
`;
