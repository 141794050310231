import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import type { SearchCampSiteQueryVariables } from '@/__generated__/public-graphql';

export const GTM_ID =
  process?.env?.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? 'G-W186PQMDYW';

export const searchEvent = (
  type: 'click_search_button' | 'calc_search_result_count' | 'initial_search',
  order: string,
  filter: SearchCampSiteQueryVariables['where']
) => {
  try {
    // @ts-ignore
    window.dataLayer.push({
      event: 'search',
      values: JSON.stringify({
        type,
        order,
        filter,
      }),
    });
    console.log('submit', { type, order, filter });
  } catch (e) {
    console.log('push error', e);
  }
};
export const pageview = (url: string) => {
  // @ts-ignore
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const useGTM = () => {
  const router = useRouter();
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);
};
export const GTMTag = () => {
  return (
    <Script
      id="gtag-base"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer', '${GTM_ID}');
              `,
      }}
    />
  );
};
