import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useGetLoginUser from './user/useGetLoginUser';

export default () => {
  const router = useRouter();
  const path = router.pathname;
  const { user, isFetched } = useGetLoginUser();
  useEffect(() => {
    if (router.isReady === false || !isFetched) {
      return;
    }
    if (user) {
      return;
    }
    if (
      path.match(/\/edit/) !== null ||
      path.match(/camp\/\[id\]\/photo/) !== null ||
      path.match(/camp\/\[id\]\/edit/) !== null ||
      path.match(/camp\/\[id\]\/log\/edit/) !== null ||
      path.match(/camper\/\[id\]\/history/) !== null ||
      path.match(/camper\/\[id\]\/resign/) !== null ||
      path.match(/camper\/\[id\]\/setting\/edit/) !== null ||
      path.match(/camper\/\[id\]\/gift/) !== null ||
      path.match(/camper\/\[id\]\/gift\/\[prizeId\]/) !== null ||
      path.match(/camper\/\[id\]\/seeds/) !== null ||
      path.match(/camper\/\[id\]\/notice/) !== null ||
      path.match(/luckybazaar\/\[uid\]\/lose\/\[id\]/) !== null ||
      path.match(/luckybazaar\/\[uid\]\/lose\/\[id\]/) !== null ||
      path.match(/luckybazaar\/\[uid\]\/win\/\[id\]/) !== null
    ) {
      router.replace('/login');
    }
  }, [user, router, path]);
};
