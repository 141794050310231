import { throttle } from 'lodash';
import { useRouter } from 'next/router';
import type { Dispatch, SetStateAction } from 'react';
import { useContext, useEffect } from 'react';

import { GlobalContext } from './state';

const FPS = 500;

export const backWithRestoreScroll = (
  setHistoryScrollValue: Dispatch<SetStateAction<Record<any, any> | null>>,
  scrollValue?: number
) => {
  const storageValue = sessionStorage.getItem('scroll');
  const { pathname } = window.location;
  if (storageValue) {
    const parseList = JSON.parse(storageValue);
    if (pathname !== undefined) {
      const targetEl = document.getElementsByTagName('main');
      const targetParam = parseList.find((e: any) => e.url === pathname);
      if (pathname.match(/search/)) {
        setTimeout(() => {
          if (scrollValue === 0 || targetParam === undefined) {
            setHistoryScrollValue(null);
            return;
          }
          if (
            targetEl[0] &&
            scrollValue &&
            targetEl[0].clientHeight > scrollValue
          ) {
            setTimeout(() => {
              window?.scrollTo(0, scrollValue);
              setTimeout(() => {
                window?.scrollTo(0, scrollValue);
              }, 500);
            }, 200);
            window?.scrollTo(0, scrollValue);
            setHistoryScrollValue(null);
          } else {
            setTimeout(
              () =>
                backWithRestoreScroll(
                  setHistoryScrollValue,
                  scrollValue || targetParam.value
                ),
              300
            );
          }
        }, 300);
      }
    }
  }
};

export const useScrollRestore = () => {
  const router = useRouter();
  const { setIsBrowserBack, setHistoryScrollValue } = useContext(GlobalContext);
  const setScrollValue = (list: [{ url: string; value: number }] | []) => {
    const storageValue = sessionStorage.getItem('scroll');
    const { pathname } = window.location;
    const scrollTop = window?.scrollY ?? 0;
    if (pathname !== undefined) {
      if (storageValue) {
        const pushList = (
          JSON.parse(storageValue) as [{ url: string; value: number }]
        )?.filter((item) => item.url !== pathname);
        pushList?.push({ url: pathname, value: scrollTop });
        sessionStorage.setItem(
          'scroll',
          JSON.stringify(pushList.splice(-2, 2))
        );
      } else {
        const pushList = list?.filter((item) => item.url !== pathname);
        pushList?.push({ url: pathname, value: scrollTop });
        sessionStorage.setItem(
          'scroll',
          JSON.stringify(pushList.splice(-2, 2))
        );
      }
    }
  };

  useEffect(() => {
    const list: [{ url: string; value: number }] | [] = [];

    setTimeout(() => {
      window?.addEventListener(
        'scroll',
        throttle(() => setScrollValue(list), FPS)
      );
      router.beforePopState(({ url, as, options }) => {
        setIsBrowserBack(true);
        backWithRestoreScroll(setHistoryScrollValue);

        const storageValue = sessionStorage.getItem('scroll');
        if (storageValue) {
          const parseList = JSON.parse(storageValue);
          setHistoryScrollValue(parseList);
        }

        return true;
      });
      return () =>
        window.removeEventListener('scroll', () => setScrollValue(list));
    }, 1500);
  }, [router]);

  return <></>;
};
