const OptimizedImage = ({
  src,
  alt,
  size: sizeInput,
  ...props
}: {
  src: string;
  alt?: string;
  size?: 'small' | 'medium' | 'large';
  [key: string]: any;
}) => {
  if (src.match(/^data:image/) !== null) {
    return <img src={src} />;
  }
  // cloudfrontかの判定
  if (src.match(process.env.NEXT_PUBLIC_CLOUDFRONT_URL as string) !== null) {
    const size = sizeInput || 'medium';
    return (
      <img
        src={`${src}?fm=webp&d=${
          // eslint-disable-next-line no-nested-ternary
          size === 'large'
            ? '800x800'
            : size === 'medium'
            ? '500x500'
            : '300x300'
        }`}
        alt={alt}
        {...props}
      />
    );
  }

  // prismicか判定
  if (src.includes('prismic')) {
    return <img src={`${src}?fm=webp`} alt={alt} {...props} />;
  }

  // jpgかpngかを判定
  if (src.match(/(png|jpg)/) === null) {
    return <img src={src} alt={alt ?? ''} {...props} />;
  }

  return (
    <img src={src.replace(/(png|jpg)/, 'webp')} alt={alt ?? ''} {...props} />
  );
};
export default OptimizedImage;
