import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import type { SlideProps } from '@mui/material/Slide';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import {
  useGetMyPointNotificationQuery,
  useUpdateMyPointNotificationMutation,
} from '@/__generated__/user-graphql';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import OptimizedImage from '@/modules/General/OptimizedImage';
import theme from '@/styles/theme';

function SlideTransition(props: React.JSX.IntrinsicAttributes & SlideProps) {
  return <Slide {...props} direction="down" />;
}

const NotificationArea = () => {
  const router = useRouter();
  const { data, refetch } = useGetMyPointNotificationQuery();
  const [pointDataList, setPointDataList] = React.useState(data?.point);
  const { mutate } = useUpdateMyPointNotificationMutation();
  useEffect(() => {
    // 2
    // 現在のパスが/camp/から始まる場合のみリフェッチする
    if (router.pathname.startsWith('/camp/')) {
      refetch().then(() => {
        setPointDataList(data?.point);
      });
    }
  }, [router]);
  useEffect(() => {
    if (!data) {
      return;
    }
    setPointDataList(data.point);
  }, [data]);

  // earnd typeに応じて、表示するメッセージを変更する
  // user 新規会員登録（初回のみ）
  // favorite お気に入り追加

  // visited 行った！保存（1回 / 日）
  // review レビュー投稿（1回 / 日）
  // from_admin 管理者からの通知
  // campsite_change_log_info キャンプ場の情報編集
  //   campsite_change_log_image_campsite サイト・宿泊施設の写真登録
  // campsite_change_log_image_facility 場内施設の写真登録
  // campsite_change_log_image_view 景色の写真登録
  const getMessages = (earned_type: string, dailyFlg: boolean) => {
    if (earned_type === 'user') {
      return '新規会員登録（初回のみ）';
    }
    if (earned_type === 'campsite_like') {
      return `お気に入り追加${dailyFlg ? '（3回 / 日）' : ''}`;
    }
    if (earned_type === 'visited') {
      return `行った！保存${dailyFlg ? '（1回 / 日）' : ''}`;
    }
    if (earned_type === 'review') {
      return `レビュー投稿${dailyFlg ? '（1回 / 日）' : ''}`;
    }
    if (earned_type === 'campsite_change_log_info') {
      return `キャンプ場の情報編集${dailyFlg ? '（1回 / 日）' : ''}`;
    }
    // earnd_typeにcampsite_change_log_imageから始まる場合、
    if (earned_type.match(/^campsite_change_log_image/)) {
      if (dailyFlg) {
        return 'キャンプ場の写真登録（1回 / 日）';
      }
      if (earned_type === 'campsite_change_log_image_campsite') {
        return `サイト・宿泊施設の写真登録`;
      }
      if (earned_type === 'campsite_change_log_image_facility') {
        return `場内施設の写真登録`;
      }
      if (earned_type === 'campsite_change_log_image_view') {
        return `景色の写真登録`;
      }
    }
    // 初回インポート対応用
    else if (earned_type === 'campsite_change_log_import_images') {
      return `キャンプ場の写真登録`;
    } else if (earned_type === 'campsite_change_log_details') {
      return `キャンプ場の情報編集`;
    }
    // 該当無し
    return '';
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    // @ts-ignore
    const idList: string[] = pointDataList?.map((pointData) => {
      return pointData.id;
    });
    // 今の時刻を取得
    const now = new Date();
    // ISO形式の文字列に変換
    const isoString = now.toISOString();
    // idListを引数にして、mutationを実行

    mutate({
      _in: idList,
      read_at: isoString,
    });
    setPointDataList([]);
  };
  const isPC = useMediaQuery('(min-width:900px)');

  return (
    <Box>
      <Box>
        <Snackbar
          open={(pointDataList ?? []).length > 0}
          onClose={handleClose}
          TransitionComponent={SlideTransition}
          key="topcenter"
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ width: '96vw', top: '12px !important' }}
        >
          <Box
            sx={{
              borderRadius: '16px',
              backgroundColor: theme.palette.green.main,
              padding: '16px 24px',
              boxShadow: ' 0px 8px 8px #3333333D',
              width: '100%',
              maxWidth: isPC ? '428px' : '100%',
            }}
          >
            <Stack column space={1.5}>
              {pointDataList?.map((pointData) => {
                return (
                  <Stack between alignCenter key={pointData.id}>
                    <Stack alignCenter space={1}>
                      <Box sx={{ maxWidth: '20px' }}>
                        <OptimizedImage
                          src="/assets/images/luckybazaar/luckybazaar_small.png"
                          alt="通知"
                          width="100%"
                          height="100%"
                        />
                      </Box>
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        lineHeight={1.5}
                        color="white"
                      >
                        {pointData.earned_type === 'from_admin'
                          ? pointData.message
                          : getMessages(
                              pointData.earned_type,
                              pointData.month_first_point_id !== null
                            )}
                      </Typography>
                    </Stack>
                    <Stack alignCenter space={0.75}>
                      <Typography
                        fontWeight={700}
                        lineHeight={1.5}
                        color="white"
                      >
                        {pointData.value}
                      </Typography>
                      <Typography
                        fontWeight={700}
                        lineHeight={1.5}
                        color="white"
                      >
                        +
                      </Typography>
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        </Snackbar>
      </Box>
    </Box>
  );
};
export default NotificationArea;
