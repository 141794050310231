import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

import type { Campsite, DialogType } from '@/types/domain';
import { Page } from '@/types/domain';

type Props = {
  children: ReactNode;
};

type State = {
  mounted: boolean;
  setMounted: React.Dispatch<React.SetStateAction<boolean>>;
  page: Page;
  setPage: React.Dispatch<React.SetStateAction<Page>>;
  openSearch: boolean;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchTotal: number | null;
  setSearchTotal: React.Dispatch<React.SetStateAction<number | null>>;
  openSuggest: boolean;
  setOpenSuggest: React.Dispatch<React.SetStateAction<boolean>>;
  suggestText: string;
  setSuggestText: React.Dispatch<React.SetStateAction<string>>;
  checkedList: string[];
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>;
  favoriteCheckedList: string[];
  setFavoriteCheckedList: React.Dispatch<React.SetStateAction<string[]>>;
  dialogStatus: DialogType;
  setDialogStatus: React.Dispatch<React.SetStateAction<DialogType>>;
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isBrowserBack: boolean;
  setIsBrowserBack: React.Dispatch<React.SetStateAction<boolean>>;
  isShowForm: boolean;
  setIsShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isEditLoading: boolean;
  setIsEditLoading: React.Dispatch<React.SetStateAction<boolean>>;
  completeTutorial: string | null;
  setCompleteTutorial: React.Dispatch<React.SetStateAction<string | null>>;
  historyScrollValue: Record<any, any> | null;
  setHistoryScrollValue: React.Dispatch<
    React.SetStateAction<Record<any, any> | null>
  >;
  searchCampsites: Campsite[] | null;
  setSearchCampsites: React.Dispatch<React.SetStateAction<Campsite[] | null>>;
};

export const GlobalContext = createContext({} as State);

export const GlobalContextProvider = ({ children }: Props) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [page, setPage] = useState<Page>(Page.TOP);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [searchTotal, setSearchTotal] = useState<number | null>(null);
  const [openSuggest, setOpenSuggest] = useState<boolean>(false);
  const [suggestText, setSuggestText] = useState<string>('');
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [favoriteCheckedList, setFavoriteCheckedList] = useState<string[]>([]);
  const [dialogStatus, setDialogStatus] = useState<DialogType>({
    open: false,
    type: '',
    props: {},
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isBrowserBack, setIsBrowserBack] = useState<boolean>(false);
  const [isShowForm, setIsShowForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditLoading, setIsEditLoading] = useState<boolean>(false);
  const [completeTutorial, setCompleteTutorial] = useState<string | null>(null);
  const [historyScrollValue, setHistoryScrollValue] = useState<Record<
    any,
    any
  > | null>(null);
  const [searchCampsites, setSearchCampsites] = useState<Campsite[] | null>(
    null
  );

  return (
    <GlobalContext.Provider
      value={{
        mounted,
        setMounted,
        page,
        setPage,
        openSearch,
        setOpenSearch,
        searchTotal,
        setSearchTotal,
        openSuggest,
        setOpenSuggest,
        suggestText,
        setSuggestText,
        checkedList,
        setCheckedList,
        favoriteCheckedList,
        setFavoriteCheckedList,
        dialogStatus,
        setDialogStatus,
        isEdit,
        setIsEdit,
        isBrowserBack,
        setIsBrowserBack,
        isShowForm,
        setIsShowForm,
        isLoading,
        setIsLoading,
        isEditLoading,
        setIsEditLoading,
        completeTutorial,
        setCompleteTutorial,
        historyScrollValue,
        setHistoryScrollValue,
        searchCampsites,
        setSearchCampsites,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
